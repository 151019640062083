import AppRoutes from "./Routes";
import { Toaster } from "sonner";

function App() {
  return (
    <>
      <Toaster position="bottom-center" richColors />
      <AppRoutes />
    </>
  );
}

export default App;


