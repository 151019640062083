import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const Homepage = lazy(() => import("./container/Homepage"));
const MyProducts = lazy(() => import("./container/MyProducts"));

const renderLoader = () => <h1>Loading</h1>;

const AppRoutes = () => {
  return (
    <Router>
      <Suspense fallback={renderLoader()}>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/my-products" element={<MyProducts />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default AppRoutes;
